import React from "react";
import { Layout, Image } from "antd";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import { useAuth0 } from "@auth0/auth0-react";

const { Header: AntHeader } = Layout;

const Header = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <AntHeader
      style={{
        backgroundColor: "transparent",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
      }}
    >
      <a href="/" style={{ display: "flex", alignItems: "center" }}>
        <img
          src={`${process.env.PUBLIC_URL}/upfellow-logo.png`}
          alt="UpFellow Logo"
          width={50}
        />
      </a>
      <div>
        {isAuthenticated ? <LogoutButton /> : <LoginButton />}
      </div>
    </AntHeader>
  );
};

export default Header; 