import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Input, Button, Card, message, Spin, Avatar, Skeleton } from "antd";
import { UserOutlined, SendOutlined } from "@ant-design/icons";
import { FaTelegramPlane } from "react-icons/fa";
import { CONTACT_INFO } from "../config";

const JobClientNamePage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [jobLink, setJobLink] = useState("");
  const [clientData, setClientData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleGetClientName = async () => {
    if (!jobLink) {
      message.error("Please enter a job link");
      return;
    }

    const upworkJobLinkPattern = /~02([a-zA-Z0-9]+)/;
    const oldJobLinkPattern = /~01([a-zA-Z0-9]+)/;

    if (!upworkJobLinkPattern.test(jobLink)) {
      if (oldJobLinkPattern.test(jobLink)) {
        message.error("We can't retrieve client info from old job");
        return;
      }
      message.error("The link must be a valid Upwork job link");
      return;
    }

    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/member/JobClientName?link=${encodeURIComponent(jobLink)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("We couldn't get the client data");
      }

      const data = await response.json();
      if (data && data.data.marketplaceJobPosting) {
        setClientData(data);
      } else {
        message.error("Client data is not available for this job link");
      }
    } catch (error) {
      console.error("Error fetching client data:", error);
      message.error("We couldn't get the client information for this link");
    } finally {
      setLoading(false);
    }
  };

  const openTelegramChannel = () => {
    window.open(`https://t.me/${CONTACT_INFO.telegramChannelName}`, "_blank");
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>
        Job Client Information
        <Button
          type="primary"
          icon={<FaTelegramPlane />}
          style={{
            backgroundColor: "black",
            borderColor: "black",
            color: "white",
            marginLeft: "15px",
          }}
          onClick={openTelegramChannel}
        >
          Follow us in Telegram
        </Button>
      </h1>
      <div style={{ maxWidth: "600px", margin: "20px 0" }}>
        <small style={{ display: "block", marginTop: "5px", color: "#888" }}>
          Enter the Upwork job link:
        </small>
        <br/>
        <Input
          placeholder="Paste job link here"
          value={jobLink}
          onChange={(e) => setJobLink(e.target.value)}
        />
        <Button 
          type="primary" 
          onClick={handleGetClientName}
          loading={loading}
          style={{ marginTop: "10px" }}
        >
          Get Client Name
        </Button>
      </div>

      {loading && <Spin tip="Loading client information..." />}

      {!clientData && !loading && (
        <Card title="Client Information" style={{ maxWidth: "600px" }}>
          <Avatar 
              icon={<UserOutlined />} 
              size="large"
              style={{ marginBottom: "10px" }}
            />
          <p><strong>Client Name:</strong> <span style={{ color: "#aaa", fontStyle: "italic" }}>Name</span></p>
          <p><strong>Client Type:</strong> <span style={{ color: "#aaa", fontStyle: "italic" }}>Type</span></p>
        </Card>
      )}

      {clientData && !loading && (
        <Card title="Client Information" style={{ maxWidth: "600px" }}>
          <Avatar 
              src={clientData.data.marketplaceJobPosting.ownership.team.photoUrl} 
              icon={<UserOutlined />} 
              size="large"
              style={{ marginBottom: "10px" }}
            />
          <p><strong>Client Name:</strong> {clientData.data.marketplaceJobPosting.ownership.team.name}</p>
          <p><strong>Client Type:</strong> {clientData.data.marketplaceJobPosting.ownership.team.type}</p>
        </Card>
      )}
    </div>
  );
};

export default JobClientNamePage;
