import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Spin, Alert, Button, Card } from "antd";

const AdminPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [serviceTokenData, setServiceTokenData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServiceTokenInfo = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/admin/upworkServiceTokenInfo`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch service upwork connection info");
        }

        const result = await response.json();
        const { serviceTokenInfo } = result;
        setServiceTokenData(serviceTokenInfo);
      } catch (error) {
        console.error("Error fetching service upwork connection info:", error);
        setError("Error fetching service upwork connection info");
      } finally {
        setLoading(false);
      }
    };

    fetchServiceTokenInfo();
  }, [getAccessTokenSilently]);

  const handleConnectUpWork = () => {
    const clientId = process.env.REACT_APP_UPWORK_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_UPWORK_REDIRECT_URI;
    const responseType = "code";

    const authUrl = `https://www.upwork.com/ab/account-security/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}`;
    window.location.href = authUrl;
  };

  if (loading) {
    return <Spin tip="Loading service upwork connection info..." />;
  }

  return (
    <div>
      <h1>Admin Page</h1>
      {error && (
        <Alert
          message="Something is wrong with Upwork token"
          description={error}
          type="error"
          showIcon
        />
      )}
      <br />
      <Button type="primary" onClick={handleConnectUpWork}>
        Connect Upwork Service Token
      </Button>
      <div style={{ marginTop: "20px" }}>
        {serviceTokenData ? (
          <Card title="Current Service Upwork Connection Info">
            <p>
              <strong>User ID:</strong> {serviceTokenData.oktaId}
            </p>
            <p>
              <strong>Modified Date Time:</strong> {new Date(serviceTokenData.modifiedDateTime).toLocaleString()}
            </p>
            <p>
              <strong>Expires At:</strong> {new Date(new Date(serviceTokenData.modifiedDateTime).getTime() + serviceTokenData.expiresIn * 1000).toLocaleString()}
            </p>
          </Card>
        ) : (
          <p>No service token connected.</p>
        )}
      </div>
    </div>
  );
};

export default AdminPage;
