import React from "react";
import { Typography } from "antd";

const { Title } = Typography;

const NotActivatedPage = () => {
  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <Title level={2}>Your account will be activated soon</Title>
    </div>
  );
};

export default NotActivatedPage;
