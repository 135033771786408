import React from "react";
import { Layout, Typography } from "antd";
import { CONTACT_INFO } from "../config";

const { Footer: AntFooter } = Layout;
const { Text } = Typography;

const Footer = () => {
  return (
    <AntFooter style={{ textAlign: "center" }}>
      <Text>
        <strong>Email: </strong>
        <a href={`mailto:${CONTACT_INFO.email}`}>{CONTACT_INFO.email}</a>
      </Text>
      <br />
      <Text>
        <strong>Telegram channel: </strong>
        <a
          href={`https://t.me/${CONTACT_INFO.telegramChannelName}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          @{CONTACT_INFO.telegramChannelName}
        </a>
      </Text>
    </AntFooter>
  );
};

export default Footer;
